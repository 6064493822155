import React, { useEffect, useState } from "react";
import NavBar from "../Nav_Footer/NavBar";
import Footer from "../Nav_Footer/Footer";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./Transaction.scss";

const TransactionStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const transactionStatus = queryParams.get("success");
  const userEmail = localStorage.getItem("userEmail");
  const name = localStorage.getItem("name") || "invalid_access_";
  const lastName = localStorage.getItem("lastName");
  const number = localStorage.getItem("number");
  const amount = localStorage.getItem("amount");
  const boat = localStorage.getItem("boat");
  const date = localStorage.getItem("date");

  useEffect(() => {
    if (name === "invalid_access_") {
      navigate("/");
      return;
    }
    const sent = sessionStorage.getItem(`booking_${boat}_${date}`) === "true";
    if (transactionStatus === "true" && !sent && name !== "invalid_access_") {
      sessionStorage.setItem(`booking_${boat}_${date}`, true);
    } else {
      if (name === "invalid_access_") {
        navigate("/");
        return;
      }
    }
  }, [[transactionStatus, navigate, userEmail, amount]]);

  return (
    <>
      <NavBar />
      <div className="transaction-status">
        {name === "invalid_access_" ? (
          ""
        ) : transactionStatus === "true" ? (
          <>
            <div className="transaction-success">
              <h1>Transaction Successful!</h1>
              <p>
                Thank you, {name} {lastName}. Your deposit {amount * 0.25} EGP
                for {boat} on {date}
              </p>
              <p>was successful.</p>
              <p>Confirmation email has been sent to: {userEmail}</p>
              <p>Contact Number: {number}</p>
            </div>
            <Link
              to="/"
              className="home-link"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
              }}
            >
              Return to Homepage
            </Link>
          </>
        ) : (
          <>
            <div className="transaction-fail">
              <h1>Transaction Failed</h1>
              <p>
                Sorry, {name} {lastName}. Your transaction could not be
                completed.
              </p>
              <p>Please check your payment details or try again later.</p>
            </div>
            <Link
              to="/"
              className="home-link"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
              }}
            >
              Return to Homepage
            </Link>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default TransactionStatus;
