import React, { useState, useEffect } from "react";
import NavBar from "../Nav_Footer/NavBar";
import Footer from "../Nav_Footer/Footer";
import "./DashBoard.scss";
import "../Booking/BookingForm.scss";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DashBoard() {
  const [bookings, setBookings] = useState([]);
  const [classes, setClasses] = useState([]);
  const [boats, setBoats] = useState([]);
  const [selectedBoat, setSelectedBoat] = useState(null); // For modal
  const [modalOpen, setModalOpen] = useState(false); // Control modal visibility
  const [bookingDate, setBookingDate] = useState(""); // Form state for date
  const [fullDay, setFullDay] = useState(false);
  const [slots, setSlots] = useState({
    slot1: false,
    slot2: false,
    slot3: false,
  });
  const [formData, setFormData] = useState({
    bookedBy: "",
    lastName: "",
    email: "",
    number: "",
    destination: "",
    date: "",
    slot1: false,
    slot2: false,
    slot3: false,
    code: "",
    status: "Admin",
    dropOff: false,
    money: 0,
  });
  const today = new Date().toISOString().split("T")[0];
  const isFormValid = () => {
    return (
      (formData.bookedBy &&
        formData.destination &&
        formData.date &&
        (formData.slot1 || formData.slot2 || formData.slot3)) ||
      (fullDay && formData.date && formData.destination !== "Bayoud")
    );
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Reset slots when new date or destination is selected
    setFormData((prev) => ({
      ...prev,
      slot1: false,
      slot2: false,
      slot3: false,
    }));

    const newDate = name === "date" ? value : formData.date;
    const newDestination =
      name === "destination" ? value : formData.destination;

    if (!newDate) return;

    try {
      const slotData = await axios.get(
        `https://${process.env.REACT_APP_BACKEND}/api/booking/available/${selectedBoat.name}/${newDate}`
      );

      if (
        (!slotData.data.slot1 ||
          !slotData.data.slot2 ||
          !slotData.data.slot3) &&
        newDestination !== "Bayoud"
      ) {
        setFullDay(false);
        setSlots({ slot1: false, slot2: false, slot3: false });
      } else if (newDestination === "Bayoud") {
        setSlots({
          slot1: slotData.data.slot1,
          slot2: slotData.data.slot2,
          slot3: false,
        });
      } else {
        setFullDay(true);
        setSlots(slotData.data);
        setFormData((prev) => ({
          ...prev,
          slot1: true,
          slot2: true,
          slot3: true,
        }));
      }
    } catch (error) {
      toast.error("Failed to load spots, please refresh the page");
    }
  };

  useEffect(() => {
    const fetchBoats = async () => {
      try {
        const response = await axios.get(
          `https://api.gounasailingclub.com/api/boats`
        );
        setBoats(response.data);
        // setLoading(false);
      } catch (err) {
        console.error("Error fetching boats:", err);
        // setError("Failed to load boats");
        // setLoading(false);
      }
    };

    fetchBoats();
  }, []);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      // transition: Flip,
    });
  const notifyFailure = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      // transition: Flip,
    });
  const formatReadableDateInEgypt = (dateString) => {
    const date = new Date(dateString);

    // Extract parts of the date and time
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format; set to false for 24-hour format
      timeZone: "Africa/Cairo", // Specify Egypt's time zone
      timeZoneName: "short", // Optional: includes time zone abbreviation
    };

    // Format the date
    return date.toLocaleString("en-US", options); // Change 'en-US' to your preferred locale
  };
  const slotText = (slot1, slot2, slot3, destination) => {
    var text = "";
    if (slot1 && slot2 && slot3) {
      text += "Full day trip";
      return text;
    } else {
      slot1 ? (text += "10:00 AM to 1:00 PM ") : (text += "");
      slot2 ? (text += "1:30 PM to 4:30 PM ") : (text += "");
      slot3 ? (text += "3:00 PM to 6:00 PM ") : (text += "");
      return text;
    }
  };

  useEffect(() => {
    fetchBoatBookings();
    fetcClassBookings();
  }, []);

  const fetchBoatBookings = async () => {
    const response = await axios.get(
      `https://${process.env.REACT_APP_BACKEND}/api/booking`
    );
    setBookings(response.data);
  };

  const fetcClassBookings = async () => {
    const classResponse = await axios.get(
      `https://${process.env.REACT_APP_BACKEND}/api/booking-class`
    );
    setClasses(classResponse.data);
  };

  const handleAccept = async (type, id) => {
    try {
      if (type === "boat") {
        await axios.patch(
          `https://${process.env.REACT_APP_BACKEND}/api/booking/accept/${id}`
        );
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === id ? { ...booking, status: "Accepted" } : booking
          )
        );
      } else {
        await axios.patch(
          `https://${process.env.REACT_APP_BACKEND}/api/booking-class/accept/${id}`
        );
        setClasses((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === id ? { ...booking, status: "Accepted" } : booking
          )
        );
      }
      notifySuccess("Booking accepted successfully");
    } catch (error) {
      notifyFailure(error.message);
    }
  };

  const handleDecline = async (type, id) => {
    try {
      if (type === "boat") {
        await axios.patch(
          `https://${process.env.REACT_APP_BACKEND}/api/booking/reject/${id}`
        );
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === id ? { ...booking, status: "Rejected" } : booking
          )
        );
      } else {
        await axios.patch(
          `https://${process.env.REACT_APP_BACKEND}/api/booking-class/reject/${id}`
        );
        setClasses((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === id ? { ...booking, status: "Rejected" } : booking
          )
        );
      }
      notifySuccess("Booking rejected successfully");
    } catch (error) {
      notifyFailure(error.message);
    }
  };

  const handleToggle = async (boatId, currentStatus) => {
    const newStatus =
      currentStatus === "available" ? "unavailable" : "available";

    // Optimistically update the state
    setBoats((prevBoats) =>
      prevBoats.map((boat) =>
        boat._id === boatId ? { ...boat, status: newStatus } : boat
      )
    );

    try {
      // Update the server
      await axios.patch(
        `https://${process.env.REACT_APP_BACKEND}/api/boats/${newStatus}/${boatId}`
      );
      notifySuccess(`Boat status updated to ${newStatus}`);
    } catch (error) {
      // If the update fails, revert the change in state
      setBoats((prevBoats) =>
        prevBoats.map((boat) =>
          boat._id === boatId ? { ...boat, status: currentStatus } : boat
        )
      );
      notifyFailure("Failed to update boat status");
    }
  };

  const handleDelete = async (type, id) => {
    try {
      if (type === "boat") {
        await axios.delete(
          `https://${process.env.REACT_APP_BACKEND}/api/booking/${id}`
        );
        // Remove deleted booking from state
        setBookings((prevBookings) =>
          prevBookings.filter((booking) => booking._id !== id)
        );
      } else {
        await axios.delete(
          `https://${process.env.REACT_APP_BACKEND}/api/booking-class/${id}`
        );
        // Remove deleted booking from state
        setClasses((prevClasses) =>
          prevClasses.filter((booking) => booking._id !== id)
        );
      }
      notifySuccess("Booking deleted successfully");
    } catch (error) {
      notifyFailure(error.message);
    }
  };
  const openModal = (boat) => {
    setSelectedBoat(boat);
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setSelectedBoat(null);
    setBookingDate("");
    setSlots({ slot1: false, slot2: false, slot3: false });
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      setModalOpen(false);
      setSelectedBoat(null);
      setBookingDate("");
      setSlots({ slot1: false, slot2: false, slot3: false });
    }
  };

  const fetchBoats = async () => {
    try {
      const response = await axios.get(
        `https://${process.env.REACT_APP_BACKEND}/api/boats`
      );
      setBoats(response.data);
    } catch (error) {
      console.error("Failed to fetch boats:", error);
    }
  };

  const refreshDashboard = () => {
    fetchBoats(); // Refresh data when a booking is made
  };

  useEffect(() => {
    fetchBoats();
  }, []);

  const destinations = ["Bayoud", "Shedwan", "Tawila", "Gaysoum"];
  const validDestinations = (boat) => {
    if (selectedBoat === "Baboshka" || boat === "Hayasso") {
      return destinations;
    } else {
      return [destinations[0]];
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await addBooking();
      await appendDataToGoogleSheet();
      await refreshDashboard();
      notifySuccess("Booking Successful");
      handleClose();
    } catch (e) {
      console.log(e);
      notifyFailure("Booking Failed");
    }
  };
  const addBooking = async () => {
    await axios.post(`https://api.gounasailingclub.com/api/booking`, {
      boatID: selectedBoat.id,
      boatName: selectedBoat.name,
      date: formData.date,
      bookedBy: formData.bookedBy + " " + formData.lastName,
      email: formData.email,
      code: formData.code,
      number: formData.number,
      destination: formData.destination,
      slot1: formData.slot1,
      slot2: formData.slot2,
      slot3: formData.slot3,
      dropOff: formData.dropOff,
      status: formData.status
    });
  };
  const appendDataToGoogleSheet = async () => {
    await axios.post(`https://api.gounasailingclub.com/api/util/append_sheet`, {
      values: [
        selectedBoat.name,
        formData.date,
        formData.bookedBy,
        formData.lastName,
        formData.email,
        formData.code,
        formData.number,
        formData.destination,
        formData.slot1,
        formData.slot2,
        formData.slot3,
        formData.dropOff,
      ],
    });
  };
  return (
    <>
      <NavBar />
      <div className="dashboard-container">
        <h1>Admin Dashboard</h1>
        <h2>Boat Bookings </h2>
        {/* Boat Bookings Section */}
        <div className="booking-section">
          {bookings.length > 0 ? (
            bookings
              .filter((booking) => new Date(booking.date) > new Date())
              .map((booking) => (
                <div key={booking._id} className="booking-card">
                  <h2>{booking.boatName}</h2>
                  <p>
                    <strong>Name:</strong> {booking.bookedBy}
                  </p>
                  <p>
                    <strong>Code:</strong> {booking.code}
                  </p>
                  <p>
                    <strong>email:</strong> {booking.email}
                  </p>
                  <p>
                    <strong>number:</strong> {booking.number}
                  </p>
                  <p>
                    <strong>Destinaion:</strong> {booking.destination}
                  </p>
                  <p>
                    <strong>Date: </strong> {booking.date}
                  </p>
                  <p>
                    <strong>Booked at:</strong>{" "}
                    {formatReadableDateInEgypt(booking.createdAt)}
                  </p>
                  <p>
                    <strong>Drop off:</strong> {booking.dropOff}
                  </p>
                  <p>
                    <strong>Booked by:</strong> {booking.status}
                  </p>
                  <p>
                    <strong>Slots: </strong>

                    {slotText(
                      booking.slot1,
                      booking.slot2,
                      booking.slot3,
                      booking.destination
                    )}
                  </p>
                  <button
                    className="decline-btn"
                    onClick={() => handleDelete("boat", booking._id)}
                  >
                    Delete
                  </button>
                </div>
              ))
          ) : (
            <div className="noBooking">
              <p>No boat bookings available</p>
            </div>
          )}
        </div>
        <h2>Sailing Class Bookings</h2>
        {/* Activity Bookings Section */}
        <div className="booking-section">
          {classes.length > 0 ? (
            classes
              .filter((booking) => new Date(booking.date) > new Date())
              .map((booking) => (
                <div key={booking._id} className="booking-card">
                  <p>
                    <strong>ID:</strong> {booking._id}
                  </p>
                  <p>
                    <strong>Booked by:</strong> {booking.bookedBy}
                  </p>
                  <p>
                    <strong>email:</strong> {booking.email}
                  </p>
                  <p>
                    <strong>number:</strong> {booking.number}
                  </p>
                  <p>
                    <strong>Date:</strong> {booking.date}
                  </p>
                  <p>
                    <strong>Booked at:</strong>{" "}
                    {formatReadableDateInEgypt(booking.createdAt)}
                  </p>
                  <p>
                    <strong>Age:</strong> {booking.age}
                  </p>
                  <p>
                    <strong>Status:</strong> {booking.status}
                  </p>
                </div>
              ))
          ) : (
            <div className="noBooking">
              <p>No class bookings available</p>
            </div>
          )}
        </div>
        <div className="boats">
          {boats.map((boat) => (
            <div className="admin-card" key={boat.id}>
              <img
                src={`https://${process.env.REACT_APP_BACKEND}/images/boats/${boat.images[0]}`}
                alt={boat.name}
              ></img>
              <h2>{boat.name}</h2>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={boat.status === "available"}
                  onChange={() => handleToggle(boat._id, boat.status)}
                />
                <span className="slider"></span>
              </label>
              <button onClick={() => openModal(boat)}>Book</button>
            </div>
          ))}
        </div>

        {/* Modal */}
        {modalOpen && (
          <div className="popup-overlay" onClick={handleOutsideClick}>
            <div className="popup-content">
              <button className="close-button" onClick={handleClose}>
                &times;
              </button>
              <h1>Book {selectedBoat.name}</h1>
              <form onSubmit={handleSubmit}>
                <label>
                  First Name:
                  <input
                    type="text"
                    name="bookedBy"
                    value={formData.bookedBy}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Last Name:
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Code (optional):
                  <input
                    type="text"
                    name="code"
                    value={formData.code}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  Number:
                  <input
                    type="number"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  Destination:
                  <select
                    name="destination"
                    value={formData.destination}
                    onChange={handleDateChange}
                    required
                  >
                    <option value="">Select a destination</option>
                    {validDestinations(selectedBoat.name).map((destination) => (
                      <option key={destination} value={destination}>
                        {destination}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  Date:
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleDateChange}
                    required
                    min={today}
                  />
                </label>

                {formData.destination === "Bayoud" ? (
                  <>
                    <label>
                      10:00 am - 1:00 pm:
                      <input
                        type="checkbox"
                        name="slot1"
                        checked={formData.slot1}
                        onChange={handleChange}
                        disabled={!slots.slot1}
                      />
                    </label>

                    <label>
                      1:30 pm - 4:30 pm:
                      <input
                        type="checkbox"
                        name="slot2"
                        checked={formData.slot2}
                        onChange={handleChange}
                        disabled={!slots.slot2}
                      />
                    </label>

                    {/* <label>
                3:00 pm - 6:00 pm:
                <input
                  type="checkbox"
                  name="slot3"
                  checked={formData.slot3}
                  onChange={handleChange}
                  disabled={!slots.slot3}
                />
              </label> */}
                  </>
                ) : (
                  <>
                    <div className="full-day">
                      <h3 style={{color:"black"}}>
                        {formData.date
                          ? fullDay
                            ? "Full day trip available"
                            : "Full day trip unavailable for this date"
                          : "Please select a date"}
                      </h3>
                      <br></br>

                      <div style={{color:"black"}}>
                        Drop-off only
                        <input
                          type="checkbox"
                          name="dropOff"
                          checked={formData.dropOff}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              dropOff: !formData.dropOff,
                            }));
                          }}
                        ></input>
                      </div>
                    </div>
                  </>
                )}
                <button
                  type="submit"
                  className="submit-button"
                  disabled={!isFormValid()}
                >
                  Book
                </button>
              </form>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}

export default DashBoard;
